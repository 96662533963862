<template>
  <v-container class="mt-4">
    <page-main
      title="Preparation checklist for building your resume"
      nextText="Summary"
      buttonTabIndex="6"
      eventName="nav"
      @nav="nav('/Summary')"
    >
      <ul class="text-body-big">
        <li class="mb-4">
          <span class="font-weight-bold">Before diving into Resumation for your resume creation journey, gathering certain materials can streamline the process and ensure your resume is comprehensive and impactful. Here's a quick guide on what you'll need:</span>
          <ul>
            <li>
              Your most recent resume.
              <ul>
                <li>
                  If you have one, it will serve as a valuable reference throughout the process.
                </li>
                <li>
                  If you're starting from scratch, no problem! Resumation will guide you step-by-step.
                </li>
              </ul>
            </li>
            <li>
              Other professional documents.
              <ul>
                <li>
                  Gather any relevant documents such as cover letters, job descriptions of roles you're targeting, and your professional portfolio or profile.
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <span class="font-weight-bold">Key Areas to Reflect On:</span>
          <ul>
            <li>
              Education:
              <ul>
                <li>
                  List institutions you've attended (school, college, university) along with degrees or certifications earned.
                </li>
                <li>
                  Include any accolades, projects, honors, and extracurricular activities.
               </li>
              </ul>
            </li>
            <li>
              Experience:
              <ul>
                <li>
                  Outline your roles and responsibilities, focusing on daily tasks and overall contributions.
                </li>
                <li>
                  Highlight professional accomplishments, showcasing your impact, proud moments, and significant learnings or achievements.
                </li>
                <li>
                  If applicable, include volunteer, leadership, or other non-paid experiences.                </li>
              </ul>
            </li>
            <li>
              Career Interests:
              <ul>
                <li>
                  Identify industries, companies, and specific job roles that interest you.
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </page-main>
  </v-container>
</template>

<script>
export default {
  name: "PreparationMaterials",
  methods: {
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    }
  }
};
</script>

<style scoped>
.container ul ul {
  list-style-type: disc;
}
</style>